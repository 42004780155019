<script setup>
import { ref, reactive, watch, computed, onMounted } from 'vue'
import { EaseChatClient } from '@/IM/initwebsdk'
import { handleSDKErrorNotifi } from '@/utils/handleSomeData'
import {
    captchaImage,
    loginCustom,
    getImToken,
    getUserInfo,
    sendSMSCode,
    loginMsm
} from '@/api/custom'
import { useStore } from 'vuex'
import { usePlayRing } from '@/hooks'
import { ElMessage } from 'element-plus'
import { getToken, setToken, removeToken } from '@/utils/auth'
import resetPassword from '../resetPassword.vue'
const store = useStore()
const loginValue = reactive({
    phoneNumber: '',
    smsCode: '',
    code: '',
    uuid: ''
})
const state = reactive({
    codeImg: '',
    timer: 0
})
const buttonLoading = ref(false)
//根据登陆初始化一部分状态
const loginState = computed(() => store.state.loginState)
watch(loginState, (newVal) => {
    if (newVal) {
        buttonLoading.value = false
        loginValue.phoneNumber = ''
        loginValue.smsCode = ''
    }
})
const rules = reactive({
    phoneNumber: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change']
        }
    ],
    smsCode: [
        {
            required: true,
            message: '请输入短信验证码',
            trigger: ['blur', 'change']
        }
    ]
})
onMounted(async () => {
    window.localStorage.removeItem('hxUserToken')
    window.localStorage.removeItem('customUserInfo')
    window.localStorage.removeItem('EASEIM_loginUser')
    window.localStorage.removeItem('customToken')
    removeToken()
    EaseChatClient.close()
    getImg()
})
const getImg = () => {
    captchaImage().then((res) => {
        //console.log("%c Line:45 🍷 res", "color:#3f7cff", res);
        state.codeImg = 'data:image/gif;base64,' + res.img
        loginValue.uuid = res.uuid
    })
}
// 发送短信验证码
const sendYzm = () => {
    if (!/^1[3-9]\d{9}$/.test(loginValue.phone)) {
        ElMessage({
            type: 'error',
            message: '手机号错误！',
            center: true
        })
        return
    }
    state.timer = 60
    changeTimer()
    let obj = {
        phone: loginValue.phone,
        type: 'phoneLogin'
    }
    sendSMSCode(obj).then((res) => {
        ElMessage({
            type: 'success',
            message: res.msg,
            center: true
        })
        loginValue.yzmRankey = res.data.yzmRankey
    })
}
const changeTimer = () => {
    if (state.timer > 0) {
        state.timer -= 1
        setTimeout(() => {
            changeTimer()
        }, 1000)
    }
}
//登陆接口调用
const loginIM = async () => {
    const { clickRing } = usePlayRing()
    clickRing()
    buttonLoading.value = true
    // 先登录客户端服务器
    loginCustom(loginValue)
        .then(async (res) => {
            // 将客户端token保存在本地
            window.localStorage.setItem(
                'customToken',
                JSON.stringify(res.token)
            )
            setToken(res.token)
            if (res.code == 200) {
              store.dispatch('GetInfo')
                // 获取IM token
                getImToken()
                    .then(async (res2) => {
                        // //console.log("%c Line:70 🍒 res2", "color:#ffdd4d", res2);
                        try {
                            // 获取个人资料
                            let userInfos = await store.dispatch(
                                'getCustomUserInfo'
                            )
                            window.localStorage.setItem(
                                `customUserInfo`,
                                JSON.stringify(userInfos.data)
                            )
                            // 用token登录IM
                            try {
                                let { accessToken } = await EaseChatClient.open(
                                    {
                                        user: userInfos.data.hxusername.toLowerCase(),
                                        // pwd: userInfos.data.userName,
                                        accessToken: res2.msg
                                    }
                                )
                                window.localStorage.setItem(
                                    `EASEIM_loginUser`,
                                    JSON.stringify({
                                        user: userInfos.data.hxusername,
                                        accessToken: accessToken
                                    })
                                )
                            } catch (error) {
                                //console.log('>>>>登陆失败', error);
                                const {
                                    data: { extraInfo }
                                } = error
                                handleSDKErrorNotifi(
                                    error.type,
                                    extraInfo.errDesc
                                )
                                EaseChatClient.close()
                                getImg()
                                buttonLoading.value = false
                            } finally {
                                buttonLoading.value = false
                            }
                        } catch (er) {
                            console.log("%c Line:93 🍧 er", "color:#7f2b82", er);
                            getImg()
                            buttonLoading.value = false
                            ElMessage({
                                type: 'error',
                                message: '登录出错，请刷新重试！',
                                center: true
                            })
                        }
                    })
                    .catch((err) => {
                        getImg()
                        buttonLoading.value = false
                        let str = err.msg
                        ElMessage({
                            type: 'error',
                            message: str,
                            center: true
                        })
                    })
            } else {
                getImg()
                buttonLoading.value = false
                ElMessage({
                    type: 'error',
                    message: res.msg,
                    center: true
                })
            }
        })
        .catch((err) => {
            getImg()
            buttonLoading.value = false
            let str = err.msg
            ElMessage({
                type: 'error',
                message: str,
                center: true
            })
        })
}
const resetPasswordRef = ref(null)
const openResetPsd = () => {
    // 打开重置密码弹窗
    resetPasswordRef.value.open()
}
</script>

<template>
  <el-form @keyup.enter="loginIM" :model="loginValue" label-position="top" :rules="rules" class="form-box">
    <el-form-item prop="username" label="账号:">
      <el-input class="login_input_style" v-model="loginValue.username" placeholder="请输入账号" clearable />
    </el-form-item>
    <el-form-item prop="password" label="密码:">
      <el-input class="login_input_style" style="width: 308px;" type="password" v-model="loginValue.password" placeholder="请输入密码" clearable />
      <div class="link" @click="openResetPsd">忘记密码</div>
    </el-form-item>
    <el-form-item prop="code" label="验证码:">
      <el-input class="login_input_style" v-model="loginValue.code" placeholder="请输入验证码" style="width: 262px;">

      </el-input>
      <img v-if="state.codeImg" :src="state.codeImg" alt="" style="height: 46px;width: 100px;vertical-align: top;margin-left: 20px;cursor: pointer;" @click="getImg()">
    </el-form-item>
    <!-- <el-form-item prop="username" label="手机号:">
      <el-input class="login_input_style" v-model="loginValue.phone" placeholder="手机号" clearable />
    </el-form-item>
    <el-form-item prop="dxyzm" label="验证码:">
      <el-input class="login_input_style" v-model="loginValue.dxyzm" placeholder="请输入验证码" style="width: 262px;">

      </el-input>
      <el-button style="margin-left: 17px;height:48px" type="primary" class="yzm-btn" :disabled="state.timer !== 0" @click="sendYzm">
        <span v-if="state.timer">{{ state.timer }}秒</span>
        <span v-else>发送验证码</span>
      </el-button>
    </el-form-item> -->
    <el-form-item>
      <div class="function_button_box">
        <el-button v-if="loginValue.username && loginValue.password && loginValue.code" class="haveValueBtn" :loading="buttonLoading" @click="loginIM">登录</el-button>
        <el-button v-else class="notValueBtn">登录</el-button>
      </div>
    </el-form-item>
    <resetPassword ref="resetPasswordRef"></resetPassword>
  </el-form>
</template>

<style lang="scss" scoped>
.form-box {
    // background: #fff;
    font-size: 16px;
}
// ::v-deep .el-form-item{
//   width: 505px;
//   flex-wrap: wrap;
// }
::v-deep .el-form-item__label {
    font-size: 16px;
    color: #ffffffb0;
}
.login_input_style {
    width: 380px;
    height: 48px;
}
.link {
    color: #5b84ff;
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
}
::v-deep .el-input__wrapper {
    background-color: #e8eaf1;
}
::v-deep .el-input__inner {
    color: #3a3a3a;

    &::placeholder {
        color: #999;
    }
}

.function_button_box {
    margin-top: 10px;
    width: 380px;

    button {
        // margin: 10px;
        width: 380px;
        height: 48px;
        // border-radius: 57px;
    }

    .haveValueBtn {
        // background: linear-gradient(90deg, #04aef0 0%, #5a5dd0 100%);
        border: none;
        // font-weight: 300;
        font-size: 24px;
        color: #fff;
        background: #5b84ff;
        border-radius: 10px;
        // &:active {
        //   background: linear-gradient(90deg, #0b83b2 0%, #363df4 100%);
        // }
    }

    .notValueBtn {
        border: none;
        font-size: 24px;
        background: #5b84ff;
        mix-blend-mode: normal;
        opacity: 0.3;
        color: #ffffff;
        cursor: not-allowed;
    }
}
</style>
