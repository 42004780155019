import { createStore } from 'vuex'
import { EaseChatClient } from '@/IM/initwebsdk'
import Conversation from './modules/conversation'
import Contacts from './modules/contacts'
import Message from './modules/message'
import Groups from './modules/goups'
import Orders from './modules/orders'
import QuickWords from './modules/quickwords'
import UserRemark from './modules/userRemark'
import afterSale from './modules/afterSale'
import { getUserInfo ,getTenantInfo,getKefuType} from '@/api/custom'
export default createStore({
    state: {
        loginState: false,
        networkStatus: true,
        isShowWarningTips: true,
        loginUserInfo: {
            hxId: '',
            nickname: '',
            avatarurl:
                'https://download-sdk.oss-cn-beijing.aliyuncs.com/downloads/IMDemo/avatar/Image5.png'
        },
        loginUserOnlineStatus: '',
        customUserInfo: {},
        tenantInfo: {},//租户信息
        kefuType: [],//客服类型
        showYanzhengma:false,
        yanzhengmaForm:{},
        yanzhengmaFormList:[],
    },
    getters: {
        loginUserInfo: (state) => state.loginUserInfo,
        loginUserOnlineStatus: (state) => state.loginUserOnlineStatus,
        tenantInfo: (state) => state.tenantInfo,
        showYanzhengma: (state) => state.showYanzhengma,
    },
    mutations: {
        SET_showYanzhengma: (state, status) => {
            state.showYanzhengma = status
        },
        SET_yanzhengmaForm: (state, obj) => {
            state.yanzhengmaForm = {...obj}
        },
        SET_yanzhengmaFormList: (state, obj) => {
            let flag = true
            if(state.yanzhengmaFormList.length){
                state.yanzhengmaFormList.forEach((r,i)=>{
                    if(r.id==obj.id){
                        flag = false
                    }
                })
                if(flag){
                    state.yanzhengmaFormList.push({...obj}) 
                }
            }else{
                state.yanzhengmaFormList = [{...obj}]
            }
            
        },
        DEL_yanzhengmaFormList: (state, obj) => {
            if(state.yanzhengmaFormList.length){
                state.yanzhengmaFormList.forEach((r,i)=>{
                    if(r.id==obj.id){
                        state.yanzhengmaFormList.splice(i,1)
                        return
                    }
                })
            }else{
                state.yanzhengmaFormList = []
            }
            
        },
        CLOSE_WARNING_TIPS: (state) => (state.isShowWarningTips = false),
        CHANGE_LOGIN_STATUS: (state, status) => {
            state.loginState = status
        },
        CHANGE_NETWORK_STATUS: (state, status) => {
            state.networkStatus = status
        },

        SET_LOGIN_USER_INFO: (state, infos) => {
            state.loginUserInfo = Object.assign(state.loginUserInfo, infos)
        },
        SET_LOGIN_USER_ONLINE_STATUS: (state, payload) => {
            //console.log('payload', payload)
            state.loginUserOnlineStatus = payload
        },
        SET_customUserInfo: (state, payload) => {
            //console.log('payload', payload)
            state.customUserInfo = { ...payload }
        },
        SET_tenantInfo: (state, payload) => {
            //console.log('payload', payload)
            state.tenantInfo = { ...payload }
        },
        SET_kefuType: (state, payload) => {
          state.kefuType = payload
      },
    },
    actions: {
        // 获取登录的租户信息
        getTenantInfo: async ({ commit },tenantId) => {
            // 获取当前租户信息
            try {
                let tenantInfo = await getTenantInfo(tenantId)
                commit('SET_tenantInfo', tenantInfo.data)
                window.localStorage.setItem(
                    `tenantInfo`,
                    JSON.stringify(tenantInfo.data)
                )
            } catch (error) {
              console.log('%c Line:63 🍰 error', 'color:#7f2b82', error)
            }
           
        },
        // 获取登录用户的虞都系统用户信息
        getCustomUserInfo: async ({ commit ,dispatch}) => {
            // 重新获取取登录用户的虞都系统用户信息
            try {
                let userInfos = await getUserInfo()
                commit('SET_customUserInfo', userInfos.data)
                window.localStorage.setItem(
                    `customUserInfo`,
                    JSON.stringify(userInfos.data)
                )
                dispatch('getTenantInfo',userInfos.data.tenantId)
                dispatch('getCustomUserKefuType')
                return userInfos
            } catch (error) {

              console.log('%c Line:63 🍰 error', 'color:#7f2b82', error)
              return false
            }
            
        },
        // 获取登录用户的客服类型
        getCustomUserKefuType: async ({ commit ,dispatch}) => {
          console.log('获取客服类型')
          try {
              let kefuInfo = await getKefuType()
              if(kefuInfo.data){
                if(kefuInfo.data.type){
                  commit('SET_kefuType', kefuInfo.data.type.split(','))
                }
                
              
                return kefuInfo.data
              }
              
          } catch (error) {

            console.log('%c Line:63 🍰 error', 'color:#7f2b82', error)
            return false
          }
          
      },

        //获取登陆用户的用户属性
        getMyUserInfo: async ({ commit }, userId) => {
            const { data } = await EaseChatClient.fetchUserInfoById(userId)
            data[userId].hxId = userId
            commit('SET_LOGIN_USER_INFO', data[userId])
        },
        //修改登陆用户的用户属性
        updateMyUserInfo: async ({ commit }, params) => {
            const { data } = await EaseChatClient.updateUserInfo({ ...params })
            //console.log('>>>>>>修改成功', data)
            commit('SET_LOGIN_USER_INFO', data)
        },
        //处理在线状态订阅变更（包含他人的用户状态）
        handlePresenceChanges: ({ commit }, status) => {
            const { userId, ext: statusType } = status || {}
            if (userId === EaseChatClient.user) {
                commit(
                    'SET_LOGIN_USER_ONLINE_STATUS',
                    statusType ? statusType : 'Unset'
                )
            } else {
                //console.log('>>>>>>不是自己的状态')

                commit('SET_FRIEND_PRESENCE', [{ ...status }])
            }
        }
    },
    modules: {
        Conversation,
        Contacts,
        Message,
        Groups,
        Orders,
        QuickWords,
        UserRemark,
        afterSale
    }
})
