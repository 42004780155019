import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import './styles/element/index.scss'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App)
app.mixin({
  data(){
    return {
      
    }
  },
  methods:{
    checkPermi(value){
      if (value && value instanceof Array && value.length > 0) {
        const permissions = store.getters && store.getters.permissions
        const permissionDatas = value
        const all_permission = "*:*:*";
        const hasPermission = permissions&&permissions.some(permission => {
          return all_permission === permission || permissionDatas.includes(permission)
        })
        if (!hasPermission) {
          return false
        }
        return true
      } else {
        console.error(`need roles! Like checkPermi="['system:user:add','system:user:edit']"`)
        return false
      }
    }
  }
})











app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(VueViewer, {
    defaultOptions: {}
})
app.mount('#app')
