<script setup>
import { ref, onMounted } from 'vue'
import { EaseChatClient } from '@/IM/initwebsdk'
import CustomImConfig from '@/views/Login/components/CustomImConfig'
import LoginInput from './components/LoginInput'
import RegisterInput from './components/RegisterInput'
// import ResetPassword from './components/ResetPassword'
//login图
const logo = require('@/assets/logo/logo.png')
// 登陆注册所用
const showComponent = ref(0)
const componType = {
    0: LoginInput,
    1: RegisterInput
    // 2: ResetPassword
}
const changeToLogin = () => {
    showComponent.value = 0
}

//服务配置
const customImConfig = ref(null)
// const showCustomImConfigModal = () => {
//   customImConfig.value.centerDialogVisible = true
// }

//SDK-Version
const IM_SDK_VERSION = EaseChatClient.version
</script>
<template>
    <el-container class="app_container">
        <div class="login_box">
            <div class="left">
                <!-- <div class="des">
          欢迎使用虞都互联,虞都互联 让每个商家都有一个属于自己的平台；
          虞都互联系统在Java语言的基础上，采用业内最先进的微服务架构理念，采用Spring
          Cloud微服务架构体系，为系统提供强有力的高并发、高可用、高存活的保证。
        </div> -->
                <div class="box">
                    <div class="box_one">你好,欢迎使用虞都互联</div>
                    <div class="box_two">
                        [让每个商家都有一个属于自己的平台]
                    </div>
                    <!-- <div class="box_three">
            虞都互联系统在Java语言的基础上，采用业内最先进的微服务架构理念，采用Spring
            Cloud微服务架构体系，为系统提供强有力的高并发、高可用、高存活的保证。
          </div> -->
                </div>
            </div>
            <div class="login_box_card">
                <div class="login_box_inner">
                    <div class="logo">
                        <img :src="logo" alt="" />
                    </div>

                    <div class="title">虞都互联商家后台</div>
                    <component
                        :is="componType[0]"
                        @changeToLogin="changeToLogin"
                    ></component>
                </div>
            </div>
            <!-- <div class="bg-box"></div> -->
        </div>
    </el-container>
</template>

<style lang="scss" scoped>
.app_container {
    width: 100vw;
    height: 100vh;
    background: #ebf2ff;
    background-size: cover;
    background-image: url('https://imgage.maimaihao.com/yuduhulian/erp/assets/img/loginbg.png');
    background-position: center center;
    .login_box {
        display: flex;
        // flex-direction: row-reverse;
        justify-content: space-between;
        // background: #04aef0;
        width: 100%;
        max-width: 1920px;
        height: 100%;
        margin: 0 auto;
        .left {
            .des {
                margin-top: 1000px;
            }
            .box {
                width: 577px;
                height: 233px;
                // background-color: #fff;
                position: fixed;
                top: 35%;
                left: 8%;
                .box_one {
                    height: 56px;
                    line-height: 56px;
                    font-family: STHeitiSC, STHeitiSC;
                    font-weight: 500;
                    font-size: 56px;
                    color: #ffffff;
                }

                .box_two {
                    height: 32px;
                    line-height: 32px;
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 800;
                    font-size: 32px;
                    color: #ffffff;
                    padding-top: 30px;
                }

                .box_three {
                    padding-top: 30px;
                    width: 577px;
                    height: 96px;
                    font-family: STHeitiSC, STHeitiSC;
                    font-weight: 500;
                    font-size: 20px;
                    color: #ffffff;
                    line-height: 30px;
                }
            }
        }
        .login_box_card {
            // height: 100%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 584px;
            // margin: 0 auto;
            border-radius: 5px;
            // background: #fff;
            // height: 100%;
            // padding-top: 10%;
            box-sizing: border-box;
            background: rgba(32, 39, 55, 0.8);
            .login_box_inner {
                height: 562px;

                background-color: transparent;
                .form-box {
                    background-color: transparent;
                }
            }

            .logo {
                text-align: center;
                img {
                  width: 100px;
                  height: 100px;
                  transition: all 0.3s;
                }
                &:hover {
                    transform: scale(1.2);
                }
            }
            .title {
                font-size: 32px;
                color: #fff;
                line-height: 45px;
                text-align: center;
                margin: 15px 0 36px 0;
            }
        }
    }
}
</style>
