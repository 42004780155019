<script setup>
import { useStore } from 'vuex'
import {
    ref,
    computed,
    defineEmits,
    watch,
    defineProps,
    toRefs,
    onMounted,
    nextTick,
    defineExpose,
    reactive
} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
// 自定义接口
import { SmsforPwd, sendYzm } from '@/api/custom'
/* store */
const store = useStore()
// const dialogVisible = ref(false)

const title = ref('忘记密码')
const showDialog = ref(false)
const ruleFormRef = ref(null)
const ruleForm = reactive({
    phonenumber: '',
    password: '',
    confirmPassword: '',
    dxyzm: '',
    yzmRankey: '',
    code: '',
    username: '',
    uuid: ''
})
const rules = reactive({
    username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    confirmPassword: [
        { required: true, message: '请输入密码', trigger: 'blur' }
    ],
    dxyzm: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})
const state = reactive({
    timer: 0
})
const open = () => {
    ruleForm.phonenumber = ''
    ruleForm.password = ''
    ruleForm.confirmPassword = ''
    ruleForm.dxyzm = ''
    ruleForm.yzmRankey = ''
    ruleForm.code = ''
    ruleForm.username = ''
    ruleForm.uuid = ''
    showDialog.value = true
}
// 发送验证码
const sendYzmFn = () => {
    if (!ruleForm.phonenumber || ruleForm.phonenumber.length != 11) {
        ElMessageBox.confirm('手机号填写不正确', '错误', {
            showCancelButton: false,
            'confirm-button-text': '确定',
            type: 'warning'
        })
        return
    }
    state.timer = 60
    changeTimer()
    // ruleForm.phonenumber
    //console.log("%c Line:132 🍏 ruleForm.phonenumber", "color:#3f7cff", ruleForm.phonenumber);
    let obj = {
        phone: ruleForm.phonenumber,
        type: 'mima'
    }
    sendYzm(obj)?.then((res) => {
        //console.log("%c Line:119 🍎 res", "color:#b03734", res);
        if (res.code == 200) {
            ruleForm.yzmRankey = res.data.yzmRankey
        } else {
            ElMessageBox.confirm(res.msg, '错误', {
                showCancelButton: false,
                'confirm-button-text': '确定',
                type: 'warning'
            })
        }
    })
}
// 倒计时
const changeTimer = () => {
    if (state.timer > 0) {
        state.timer -= 1
        setTimeout(() => {
            changeTimer()
        }, 1000)
    }
}

const submitForm = async () => {
    await ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
            if (ruleForm.password != ruleForm.confirmPassword) {
                ElMessageBox.confirm('两次输入的密码不一致', '错误', {
                    showCancelButton: false,
                    'confirm-button-text': '确定',
                    type: 'warning'
                })
                return
            }
            // 忘记密码
            SmsforPwd(ruleForm)
                ?.then((res) => {
                    //console.log("%c Line:105 🧀 res", "color:#42b983", res);
                    if (res.code && res.code == 500) {
                        ElMessage({
                            message: res.msg,
                            type: 'success'
                        })
                        return
                    }
                    ElMessage({
                        message: '重置密码成功,请用新密码登录！',
                        type: 'success'
                    })
                    showDialog.value = false
                })
                .catch((err) => {
                    ElMessageBox.confirm(err.msg, '错误', {
                        showCancelButton: false,
                        'confirm-button-text': '确定',
                        type: 'warning'
                    })
                })
        } else {
            //console.log('error submit!', fields)
        }
    })
}

defineExpose({
    open
})
</script>
<template>
    <el-dialog
        v-model="showDialog"
        :close-on-click-modal="false"
        width="450px"
        :title="title"
        draggable
    >
        <div class="reset-box">
            <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="auto"
                label-position="left"
            >
                <el-form-item label="手机号:" prop="phonenumber">
                    <el-input
                        size="large"
                        v-model="ruleForm.phonenumber"
                        placeholder="请输入手机号"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="新密码:" prop="password">
                    <el-input
                        size="large"
                        type="password"
                        v-model="ruleForm.password"
                        placeholder="请输入6-16位内的登录密码"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="再次输入新密码:" prop="confirmPassword">
                    <el-input
                        size="large"
                        type="password"
                        v-model="ruleForm.confirmPassword"
                        placeholder="请再次输入密码"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="短信验证码:" prop="dxyzm">
                    <el-input
                        size="large"
                        v-model="ruleForm.dxyzm"
                        placeholder="请输入验证码"
                        style="width: 250px"
                    >
                    </el-input>
                    <el-button
                        type="primary"
                        class="yzm-btn"
                        :disabled="state.timer !== 0"
                        @click="sendYzmFn"
                        size="large"
                    >
                        <span v-if="state.timer">{{ state.timer }}秒</span>
                        <span v-else>发送验证码</span>
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        size="large"
                        @click="submitForm(ruleFormRef)"
                        style="width: 360px"
                    >
                        <span>提交</span>
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>

<style lang="scss" scoped>
.reset-box {
    // padding: 30px;
    width: 370px;
    margin: 0 auto;
    .el-input {
        width: 360px;
        overflow: hidden;
    }
}
::v-deep .el-form-item__label{
  font-size: 16px;
  color: #333 !important;
}
</style>
