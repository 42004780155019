const SESSION_MESSAGE_TYPE = {
    img: '[图片]',
    file: '[文件]',
    audio: '[语音]',
    loc: '[位置]',
    goods: '[商品]',
    backlist: '[回收记录]',
    order: '[订单]',
    b_backlist: '[回收记录]',
    b_backclueorder: '[回收订单]',
    b_appraiseorder: '[鉴定订单]',
    b_appraiseordercreate: '[鉴定下单]',
    // b_appraiseordercreate
    search_result: '[搜索结果]',
    search_link: '[搜索链接]',
    info: '[提示信息]',
    b_backlink: '[回收链接]',
    sm_link: '[认证链接]',
}

const CUSTOM_TYPE = {
    image: '图片',
    audio: '语音',
    video: '视频',
    file: '文件',
    goods: '商品',
    backlist: '回收记录',
    b_backlist: '回收记录',
    b_backclueorder: '回收订单',
    b_appraiseorder: '鉴定订单',
    b_appraiseordercreate: '鉴定下单',
    search_result: '搜索结果',
    search_link: '搜索链接',
    info: '提示信息',
    b_backlink: '回收链接',
    sm_link: '认证链接',
    order: '订单',
    inform: '系统通知'
}
const ALL_MESSAGE_TYPE = {
    TEXT: 'txt',
    IMAGE: 'img',
    AUDIO: 'audio',
    LOCAL: 'loc',
    VIDEO: 'video',
    FILE: 'file',
    CUSTOM: 'custom',
    CMD: 'cmd',
    INFORM: 'inform', //这个类型不在环信消息类型内，属于自己定义的一种系统通知类的消息。
    GOODS:'goods',
    BACKLIST:'backlist',
    B_BACKLIST:'b_backlist',
    B_BACKORDER:'b_backclueorder',
    b_appraiseorder:'b_appraiseorder',
    b_appraiseordercreate:'b_appraiseordercreate',
    SEARCH_RESULT:'search_result',
    ORDER:'order'
}
const CHAT_TYPE = {
    SINGLE: 'singleChat',
    GROUP: 'groupChat'
}

export default {
    SESSION_MESSAGE_TYPE,
    CUSTOM_TYPE,
    ALL_MESSAGE_TYPE,
    CHAT_TYPE
}
