import _ from 'lodash'
import { userRemarkList, getInfo } from '@/api/custom.js'
const UserRemark = {
    state: {
        remarkList: [],
        permissions: [],
        roles: []
    },
    getters: {
        permissions: (state) => state.permissions
    },
    mutations: {
        SET_REMARK_LIST: (state, payload) => {
            state.remarkList = payload
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        }
    },
    actions: {
        // 获取用户备注列表
        getUserRemarkList: async ({ commit }, myId) => {
            try {
                const { rows } = await userRemarkList(myId)

                let remarksMap = {}
                if (rows && rows.length) {
                    for (let i = 0; i < rows.length; i++) {
                        let remark = rows[i]
                        let keyid =
                            remark.fromHxId == myId
                                ? remark.toHxId
                                : remark.fromHxId
                        remarksMap[keyid] = remark
                    }
                }
                //console.log("%c Line:27 🧀拿到用户备注列表 remarksMap", "color:#e41a6a", remarksMap);
                if (rows) {
                    commit('SET_REMARK_LIST', remarksMap)
                } else {
                    commit('SET_REMARK_LIST', {})
                }
            } catch (error) {
                commit('SET_REMARK_LIST', {})
            }
        },
        // 获取用户信息
        GetInfo({ commit, dispatch, state }) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then((res) => {
                        if (res.roles && res.roles.length > 0) {
                            // 验证返回的roles是否是一个非空数组
                            commit('SET_ROLES', res.roles)
                            commit('SET_PERMISSIONS', res.permissions)
                        } else {
                            commit('SET_ROLES', ['ROLE_DEFAULT'])
                        }
                        resolve(res)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}

export default UserRemark
